<template>
  <div>
    <el-upload
      class="upload-demo"
      drag
      action="https://jsonplaceholder.typicode.com/posts/"
      multiple
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        支持扩展名：xlsx .doc .docx .pdf .jpg
      </div>
    </el-upload>
  </div>
</template>

<script>

export default {
  name: 'upload_materiel',
  props: {
    value: Object,
    disabled: Boolean,
    type: String,
    getField: Function,
  },
  data: () => ({
  }),
  created() {
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
/deep/.el-upload-dragger{
  width: 560px;
  max-width: 560px;
}
</style>
