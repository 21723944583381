<template>
  <div class="list">
    <div class="list-left">
      <div class="list-des">总计{{ rightOptions.length }}条</div>
      <div class="list-item">
        <el-scrollbar
          wrap-class="modal-scrollbar"
          class="dialog-style"
          style="height: 428px"
          :native="false"
          view-class="modal-container"
          :noresize="false"
          tag="div"
        >
          <div class="box">
            <el-checkbox-group v-model="checkedAll" @change="checkedChangeAll">
              <el-checkbox label="all">全部</el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group v-model="leftCheckedOptions">
              <el-checkbox
                v-for="(item, k) in leftOptions"
                :label="item"
                :key="k"
                @change="checkedChangeOne(item)"
                :indeterminate="item.indeterminate"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-scrollbar>
        <el-scrollbar
          wrap-class="modal-scrollbar"
          class="dialog-style"
          style="height: 428px"
          :native="false"
          view-class="modal-container"
          :noresize="false"
          tag="div"
        >
          <div class="box">
            <el-checkbox-group v-model="rightCheckedOptions">
              <el-checkbox
                v-for="(item, k) in rightOptions"
                :label="item"
                :key="k"
                @change="checkedChangeTwo(item)"
                >{{ item.productName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="list-right">
      <div class="list-des">已选{{ rightCheckedOptions.length }}条</div>
      <div class="list-item">
        <el-scrollbar
          wrap-class="modal-scrollbar"
          class="dialog-style"
          style="height: 428px"
          :native="false"
          view-class="modal-container"
          :noresize="false"
          tag="div"
        >
          <div class="box">
            <el-checkbox-group v-model="rightCheckedOptions">
              <el-checkbox
                v-for="(item, k) in rightCheckedOptions"
                :label="item"
                :key="k"
                @change="checkedChangeTwo(item)"
                >{{ item.fatherName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-scrollbar>
        <el-scrollbar
          wrap-class="modal-scrollbar"
          class="dialog-style"
          style="height: 428px"
          :native="false"
          view-class="modal-container"
          :noresize="false"
          tag="div"
        >
          <div class="box">
            <el-checkbox-group v-model="rightCheckedOptions">
              <el-checkbox
                v-for="(item, k) in rightCheckedOptions"
                :label="item"
                :key="k"
                @change="checkedChangeTwo(item)"
                >{{ item.productName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../../../../../../../../utils/request';

export default {
  name: 'add_materiel',
  props: {
    value: Array,
    disabled: Boolean,
    type: String,
    getField: Function,
  },
  data: () => ({
    indeterminate: false,
    checkedAll: [], // 全选
    leftOptions: [
      {
        name: 'WUJL00001/兰州销售区宴席活',
        products: [
          { productName: '金徽酒一' },
          { productName: '金徽酒二' },
          { productName: '金徽酒三' },
        ],
      },
      {
        name: 'WUJL00002/贵州销售区宴席活',
        products: [
          { productName: '茅台酒一' },
          { productName: '茅台酒二' },
          { productName: '茅台酒三' },
          { productName: '茅台酒四' },
          { productName: '茅台酒五' },
        ],
      },
      {
        name: 'WUJL00003/四川销售区宴席活',
        products: [
          { productName: '丰谷酒一' },
          { productName: '丰谷酒二' },
          { productName: '丰谷酒三' },
          { productName: '丰谷酒四' },
        ],
      },
    ], // 物料区域
    rightOptions: [], // 物料
    leftCheckedOptions: [], // 添加物料区域
    rightCheckedOptions: [], // 添加物料
  }),
  created() {
    this.getList();
  },
  methods: {
    checkedChangeAll() {
      this.rightCheckedOptions = [];
      this.leftCheckedOptions = [];
      this.leftOptions.filter((v) => {
        Object.defineProperty(v, 'isChecked', {
          value: this.checkedAll.length > 0,
          writable: true,
        });
        Object.defineProperty(v, 'indeterminate', {
          value: false,
          writable: true,
        });
        v.products.map((item) => {
          Object.defineProperty(item, 'isChecked', {
            value: this.checkedAll.length > 0,
            writable: true,
          });
          return item;
        });
        return v;
      });
      this.leftOptions.filter((v) => {
        if (v.isChecked) {
          this.leftCheckedOptions.push(v);
        }
        v.products.map((item) => {
          if (item.isChecked && v.isChecked) {
            this.rightCheckedOptions.push(item);
          }
          return item;
        });
        return v;
      });
      this.onGetChecked();
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
    // 获取列表
    getList() {
      request
        .post('/tpm/tpmLiqueurActController/list', { pageSize: -1 })
        .then((res) => {
          if (res.success) {
            this.leftOptions.filter((v, k) => {
              Object.defineProperty(v, 'isChecked', {
                value: false,
                writable: true,
              });
              Object.defineProperty(v, 'indeterminate', {
                value: false,
                writable: true,
              });
              Object.defineProperty(v, 'no', {
                value: k,
                writable: true,
              });
              v.products.map((item, index) => {
                Object.defineProperty(item, 'fatherName', { value: v.name });
                Object.defineProperty(item, 'no', { value: [k, index] });
                Object.defineProperty(item, 'isChecked', {
                  value: false,
                  writable: true,
                });
                this.rightOptions.push(item);
                return item;
              });
              return v;
            });
            this.value.map((item, index) => {
              Object.defineProperty(item, 'isChecked', {
                value: false,
                writable: true,
              });
              this.checkedChangeTwo(item);
              return item;
            });
          }
        });
    },
    // 初始化数据
    initData() {},

    // 选择监听
    checkedChangeOne(val) {
      if (val.indeterminate) {
        this.leftOptions[val.no].isChecked = true;
        this.leftOptions[val.no].indeterminate = false;
      } else {
        this.leftOptions[val.no].isChecked = !val.isChecked;
      }

      this.leftOptions[val.no].products.map((v) => {
        Object.defineProperty(v, 'isChecked', {
          value: this.leftOptions[val.no].isChecked,
          writable: true,
        });
        return v;
      });
      this.rightCheckedOptions = [];
      this.leftCheckedOptions = [];
      this.leftOptions.filter((v) => {
        if (v.isChecked) {
          this.leftCheckedOptions.push(v);
        }
        v.products.map((item) => {
          if (item.isChecked && v.isChecked) {
            this.rightCheckedOptions.push(item);
          }
          return item;
        });
        return v;
      });
      this.onGetChecked();
    },
    checkedChangeTwo(val) {
      this.leftOptions[val.no[0]].products[
        val.no[1]
      ].isChecked = !val.isChecked;
      let trueNum = 0;
      console.log(this.leftOptions[val.no[0]].products);
      this.leftOptions[val.no[0]].products.map((v) => {
        if (v.isChecked) {
          trueNum += 1;
        }
        return v;
      });
      if (trueNum === this.leftOptions[val.no[0]].products.length) {
        this.leftOptions[val.no[0]].isChecked = true;
        this.leftOptions[val.no[0]].indeterminate = false;
      } else if (trueNum === 0) {
        this.leftOptions[val.no[0]].isChecked = false;
        this.leftOptions[val.no[0]].indeterminate = false;
      } else {
        this.leftOptions[val.no[0]].isChecked = true;
        this.leftOptions[val.no[0]].indeterminate = true;
      }
      this.rightCheckedOptions = [];
      this.leftCheckedOptions = this.leftOptions.filter((v) => {
        v.products.map((item) => {
          if (item.isChecked) {
            this.rightCheckedOptions.push(item);
          }
          return item;
        });
        return v.isChecked;
      });
      // this.rightCheckedOptions = val;
      this.onGetChecked();
    },
    onGetChecked() {
      this.$emit('onGetChecked', this.rightCheckedOptions);
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  height: 100%;
  display: flex;
  border: 1px solid #ccc;
  .list-left {
    width: 50%;
    border-right: 1px solid #ccc;
  }
  .list-right {
    width: 50%;
  }

  .list-item {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    .dialog-style {
      width: 50%;
      &:nth-child(1) {
        border-right: 1px solid #ccc;
      }
    }
  }

  .list-des {
    width: 100%;
    color: #9c9c9c;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }

  .el-checkbox {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
