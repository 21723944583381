<template>
  <div class="custom-select">
    <div class="activity-select" @click="addTableList">
      <el-input
        :placeholder="disabled ? '' : '活动申请名称'"
        v-model="value"
        :disabled="disabled"
        :readonly="true"
      ></el-input>
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'inputclick',
  components: {
    SelectConfig,
  },
  props: {
    value: String,
    disabled: Boolean,
    type: String,
    getField: Function,
  },
  data: () => ({
    checkData: [],
  }),
  methods: {
    // 新增弹窗
    addTableList() {
      if (this.disabled) {
        return;
      }
      const params = {
        functionCode: 'tpm_activity_activity_list',
        // data: this.checkData || [],
        data: [],
        selectionList: [],
        idKey: 'actCode',
        paramData: {},
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      this.$emit('change', val[0]);
    },
  },
};
</script>

<style lang="less" scoped></style>
