<template>
  <div class="table">
    <div class="buttons" v-if="!disabled">
      <el-button type="primary" @click="addTableList">新增</el-button>
      <el-button type="danger" @click="clearSelectConfig">清空</el-button>
    </div>
    <vxe-table
      ref="fineTable"
      resizable
      show-overflow
      :data="list"
      :radio-config="{ highlight: true }"
      :edit-config="{
        trigger: 'click',
        mode: 'cell',
        immediate: true,
        activeMethod: activeCellMethod,
      }"
    >
      <vxe-table-column
        v-for="item in columns"
        :key="item.field"
        :field="item.field"
        :title="item.title"
        :formatter="formatter"
        :min-width="135"
        :edit-render="editRender(item)"
      >
        <template v-if="item.render" v-slot="{ row }">
          <span>{{ item.render && item.render(row) }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        title="操作"
        width="60"
        fixed="right"
        align="center"
        v-if="this.columns.length > 0"
      >
        <template v-slot="{ row, rowIndex }">
          <el-dropdown @command="(val) => clickCell({ val, row, rowIndex })">
            <span class="el-dropdown-link">更多</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="upload">核销资料</el-dropdown-item>
              <el-dropdown-item command="activity">活动取证资料</el-dropdown-item>
              <el-dropdown-item command="delete" v-if="!disabled">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <!-- 抽屉 -->
    <Modal :modalConfig="modalConfig" ref="modalForm" @onClose="closeModal">
      <vxe-table border ref="fTable" :data="cancelValue">
        <!-- <vxe-table-column title="活动明细名称" field="fineName"></vxe-table-column> -->
        <vxe-table-column title="要求核销资料" field="exampleName"></vxe-table-column>
        <vxe-table-column title="描述" field="remarks"></vxe-table-column>
        <vxe-table-column title="示例" field="pictureRespVos">
          <template v-slot="{ row }">
            <div class="img-list">
              <!-- <div class="img-item" v-for="(item, index) in row.pictureRespVos" :key="index">
                <img :src="item.urlAddress" class="img" />
              </div>-->
              <el-image
                style="max-width: 80px;"
                :src="getImgUrls(row.pictureRespVos)[0]"
                :preview-src-list="getImgUrls(row.pictureRespVos)"
              ></el-image>
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column
          title="上传"
          width="130"
          fixed="right"
          align="center"
          v-if="modalName === 'upload'"
        >
          <template v-slot="{ row }">
            <el-upload
              class="upload-demo"
              v-if="!disabled"
              action="/upload/uploadController/uploadOssList"
              :headers="{
                loginUserToken: Storage.l.get('TokenKey')
              }"
              :on-remove="handleRemove"
              :on-success="(res) => handleSuccess(res, row)"
              multiple
              accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
              :file-list="fileList[row.exampleCode] || []"
            >
              <span class="el-dropdown-link">点击上传</span>
            </el-upload>
            <div v-else class="file-list-detail">
              <div
                v-for="(item,index) in fileList[row.exampleCode] || []"
                class="file-list"
                :key="index"
              >
                <i class="el-icon-tickets"></i>
                <div class="file-name">{{item.name}}</div>
                <a
                  :href="`${item.url}?response-content-type=application/octet-stream`"
                  :download="item.name"
                >
                  <i class="el-icon-download"></i>
                </a>
              </div>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <slot name="footer">
          <!-- <el-button icon="el-icon-check" type="primary" @click="onModalOk">确定</el-button> -->
          <el-button
            icon="el-icon-close"
            v-if="modalName !== 'add_materiel'"
            type="danger"
            @click="closeModal"
          >关闭</el-button>
        </slot>
      </div>
    </Modal>
  </div>
</template>

<script>
import Storage from '@/found/utils/storage';
import SelectConfig from '../../../../../../../../../components/select_org_cus_ter/select_config.vue';
import Modal from '../../../../../../../../../components/modal';
import request from '../../../../../../../../../utils/request';

export default {
  name: 'cancel_table',
  props: {
    value: Array,
    columns: Array,
    disabled: Boolean,
    isUpdate: Boolean,
    auditFormCode: String,
    actCode: String,
    fineCode: String,
    isAllowRepeatAudit: String,
  },
  data() {
    return {
      Storage,
      list: [],
      modalName: '', // 打开模块类型
      modalConfig: {}, // 弹框信息
      cancelValue: [],
      selectCode: '',
      fileList: {},
    };
  },
  components: {
    SelectConfig,
    Modal,
  },
  watch: {
    value(val) {
      this.list = val;
    },
    actCode() {
      if (this.isUpdate) {
        this.clearSelectConfig();
      }
    },
  },
  methods: {
    // 示例图片地址
    getImgUrls(data) {
      let list = [];

      if (data) {
        list = data.map((v) => v.urlAddress);
      }

      return list;
    },
    formatter({ cellValue, row, column }) {
      let text = cellValue;
      switch (column.property) {
        case 'billAuditAmount': {
          if (!cellValue) {
            text = 0;
          }
          break;
        }
        case 'materialsAmount': {
          if (!cellValue) {
            text = 0;
          }
          break;
        }
        case 'amount': {
          if (!cellValue) {
            text = 0;
          }
          break;
        }
        case 'applyAmount': {
          if (!cellValue) {
            text = 0;
          }
          break;
        }
        case 'isAllAudit': {
          if (cellValue === 'N') {
            text = '否';
          } else if (cellValue === 'Y') {
            text = '是';
          }
          break;
        }
        default: {
          text = cellValue;
          break;
        }
      }
      return text;
    },
    activeCellMethod({ column, columnIndex }) {
      if (column.property === 'materialsAmount' && !this.disabled) {
        return true;
      } if (
        column.property === 'isAllAudit'
        && !this.disabled
        && this.isAllowRepeatAudit !== 'Y'
      ) {
        return true;
      }

      return false;
    },
    // 配置自定义组件
    editRender(item) {
      const { type, title, field } = item;
      let tmp = null;
      if (type && !this.disabled) {
        tmp = {
          name: type,
          props: {
            placeholder: `请输入${title}`,
            transfer: true,
          },
          events: {
            change: ({ row }) => {
              const actDetailCodeList = this.list.map((v) => v.actDetailCode);
              this.$emit('onGetfValue', this.list, actDetailCodeList);
            },
          },
        };

        if (
          [
            'amount',
            'applyAmount',
            'materialsAmount',
            'billAuditAmount',
          ].includes(field)
        ) {
          tmp = {
            ...tmp,
            name: '$input',
            props: {
              ...tmp.props,
              type: 'number',
              min: '0',
            },
          };
        }

        if (type === 'select') {
          tmp = {
            ...tmp,
            name: 'ElSelect',
            props: {
              placeholder: `请选择${title}`,
              transfer: true,
              filterable: true,
              clearable: true,
              remote: true,
              reserveKeyword: true,
              remoteMethod: (query) => false,
              loading: this.loading,
            },
          };
          // 绑定数组
          switch (field) {
            case 'isAllAudit': {
              tmp.options = [
                { value: 'Y', label: '是' },
                { value: 'N', label: '否' },
              ];
              break;
            }
            default: {
              break;
            }
          }
        }
      }
      return tmp;
    },
    // 新增弹窗
    addTableList() {
      if (!this.fineCode) {
        this.$message({
          type: 'error',
          message: '请先选择活动申请名称',
        });
        return;
      }
      if (!this.auditFormCode) {
        this.$message({
          type: 'error',
          message: '请配置活动明细模板',
        });
        return;
      }
      const params = {
        functionCode: 'tpm_audit_detail_list',
        data: this.list || [],
        selectionList: [],
        idKey: 'actDetailCode',
        paramData: {
          actCode: this.actCode,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 组件回调
    onGetSelect(val) {
      this.list = val;
      this.getActDetailCodeList();
    },
    // 清空列表
    clearSelectConfig() {
      this.list = [];
      this.getActDetailCodeList();
    },
    getActDetailCodeList() {
      this.$emit('onGetfValue', this.list /* actDetailCodeList */);
    },

    onGetChecked(val) {
      this.showMateriel = val;
    },
    // 行操作事件
    clickCell({ val, row, rowIndex }) {
      this.modalName = val;
      if (val === 'upload') {
        this.openModal({ val, row, rowIndex });
      } else if (val === 'activity') {
        this.openModal({ val, row, rowIndex });
      } else if (val === 'delete') {
        this.deleteClick({ val, row, rowIndex });
      }
      return { val, row };
    },
    // 删除单行
    deleteClick({ val, row, rowIndex }) {
      this.list.splice(rowIndex, 1);
      this.getActDetailCodeList();
    },
    getvalue(rowIndex) {
      const list = this.list[rowIndex].fileVos || [];

      request
        .post('/tpm/tpmAuditController/costTypeFineListByFineCode', {
          fineCodeList: [this.fineCode],
          auditFileReqVos: [],
        })
        .then((res) => {
          if (res.success) {
            const data = res.result[0].auditRequires || [];

            if (data.length > 0 && list.length > 0) {
              data.forEach((v) => {
                const subitem = list.filter(
                  (child) => v.exampleCode === child.exampleCode
                    && child.auditFileType === 1,
                );

                this.fileList[v.exampleCode] = subitem.map((l) => {
                  const val = l;

                  if (!l.name) {
                    val.name = val.auditRequireNames;
                    val.fileName = val.auditRequireNames;
                    val.url = val.addressUrl;
                  }
                  return val;
                });
              });
            }
            this.cancelValue = data;
          }
        });
    },

    // 打开抽屉
    openModal({ val, row, rowIndex }) {
      if (val === 'upload') {
        this.selectCode = row.actDetailCode;
        this.getvalue(rowIndex);
        this.modalConfig = {
          visible: true,
          type: 'Drawer',
          title: '上传核销资料',
        };
      }
      if (val === 'activity') {
        this.modalConfig = {
          visible: true,
          type: 'Drawer',
          title: '活动取证资料',
        };
      }
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { title: '', type: '', visible: false };
    },
    // 确定弹窗
    onModalOk() {
      if (this.modalName === 'add_materiel') {
        this.checkedMateriel = this.showMateriel;
        this.value.sVal = this.checkedMateriel.optionOne;
        this.sVal = this.checkedMateriel.optionOne;
        this.$emit('input', this.value);
      }
      this.modalConfig = { title: '', type: '', visible: false };
      this.modalName = '';
    },
    // 点击上传
    beforeUpload: (res) => {
      const uploadRule = {
        accept: '.jpg, .jpeg, .png, .JPG, .JPEG, .PNG',
        fileSize: '20',
      };
      const suffix = res.name.split('.')[res.name.split('.').length - 1];
      if (
        !uploadRule.accept.includes(suffix)
        && !uploadRule.accept.includes('*')
      ) {
        this.$message.error(`请上传${uploadRule.accept}格式文件`);
        return false;
      }

      const file = this.getRule(item.field);
      if (uploadRule.fileSize) {
        if (res.size / (1024 * 1024) > uploadRule.fileSize) {
          this.$message.error(`文件大小超过${uploadRule.fileSize}M`);
        }
        return res.size / (1024 * 1024) <= uploadRule.fileSize;
      }
      return true;
    },
    handleRemove(file, fileList) {
      this.list = this.list.map((item) => {
        const v = item;
        if (v.actDetailCode === this.selectCode) {
          v.fileVos = fileList;
        }

        return v;
      });

      this.$emit('onGetfValue', this.list);
    },
    handleSuccess(res, row) {
      if (res.success) {
        this.list = this.list.map((item) => {
          const v = item;
          if (v.actDetailCode === this.selectCode) {
            const _list = v.fileVos || [];

            res.result.forEach((sub) => {
              _list.push({
                ...sub,
                addressUrl: sub.url,
                auditRequireNames: sub.fileName,
                fineCode: this.fineCode,
                auditFileType: 1,
                exampleCode: row.exampleCode,
              });
            });
            v.fileVos = _list;
          }

          return v;
        });

        this.$emit('onGetfValue', this.list);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  box-sizing: border-box;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.buttons {
  margin-bottom: 12px;
}

// .img-list {
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;

//   .img-item {
//     width: 48%;
//     flex-shrink: 0;
//     margin-bottom: 5px;

//     .img {
//       width: 100%;
//       height: 100%;
//     }
//   }
// }

.file-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  .file-name {
    flex: 1;
    padding: 0 10px;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  > i {
    padding: 0 5px;
  }
}

.file-list-detail {
  padding: 10px 0;
}
</style>
