<template>
  <div class="table">
    <div class="buttons" v-if="!disabled">
      <el-button type="primary" @click="addTableList">新增</el-button>
      <el-button type="danger" @click="clearSelectConfig">清空</el-button>
    </div>
    <vxe-table border show-overflow ref="fTable" :data="list">
      <vxe-table-column title="商品名称" field="fatherName"></vxe-table-column>
      <vxe-table-column title="商品编码" field="actCode"></vxe-table-column>
      <vxe-table-column title="借领标题" field="payTypeName"></vxe-table-column>
      <vxe-table-column
        title="借领编码"
        field="createOrgName"
      ></vxe-table-column>
      <vxe-table-column
        title="借领组织"
        field="createOrgName1"
      ></vxe-table-column>
      <vxe-table-column
        title="借领责任方"
        field="createOrgName2"
      ></vxe-table-column>
      <vxe-table-column
        title="申请时间"
        field="createOrgName3"
      ></vxe-table-column>
      <vxe-table-column
        title="预计清账时间"
        field="registerTotalAmount"
      ></vxe-table-column>
      <vxe-table-column
        title="单价"
        field="auditTotalAmount"
      ></vxe-table-column>
      <vxe-table-column
        title="出库数量"
        field="auditTotalAmnt"
      ></vxe-table-column>
      <vxe-table-column
        title="本次核销数量"
        field="auditTalAmnt"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="60" fixed="right" align="center">
        <template v-slot="{ row, rowIndex }">
          <el-dropdown @command="(val) => clickCell({ val, row, rowIndex })">
            <span class="el-dropdown-link"> 更多 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="upload"
                >物料核销资料
              </el-dropdown-item>
              <el-dropdown-item command="delete">删除 </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 抽屉 -->
    <Modal :modalConfig="modalConfig" ref="modalForm" @onClose="closeModal">
      <AddMateriel
        v-if="modalName === 'add_materiel'"
        :value="list"
        @onGetChecked="onGetChecked"
      />
      <UploadMateriel v-if="modalName === 'upload'" />
      <div class="dialog-footer">
        <slot name="footer">
          <el-button icon="el-icon-check" type="primary" @click="onModalOk"
            >确定</el-button
          >
          <el-button
            icon="el-icon-close"
            v-if="modalName === 'upload'"
            type="danger"
            @click="closeModal"
            >取消</el-button
          >
        </slot>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../../../../../../../components/modal';
import AddMateriel from './add_materiel';
import UploadMateriel from './upload_materiel';

export default {
  name: 'cancel_table',
  components: {
    Modal,
    AddMateriel,
    UploadMateriel,
  },
  props: {
    value: Array,
    disabled: Boolean,
    type: String,
    getField: Function,
  },
  data: () => ({
    list: [],
    showList: [],
    modalName: '', // 打开模块类型
    modalConfig: {}, // 弹框信息
    cancelValue: {},
  }),
  watch: {
    value(val) {
      this.list = val;
    },
  },
  methods: {
    // 新增弹窗
    addTableList() {
      this.modalName = 'add_materiel';
      this.modalConfig = {
        visible: true,
        type: 'modal',
        title: '添加物料',
      };
    },
    // 清空列表
    clearSelectConfig() {
      this.list = [];
      this.$emit('onGetfValue', this.list);
    },

    onGetChecked(val) {
      this.showList = val;
    },
    // 行操作事件
    clickCell({ val, row, rowIndex }) {
      this.modalName = val;
      if (val === 'upload') {
        this.openModal({ val, row, rowIndex });
      } else if (val === 'delete') {
        this.deleteClick({ val, row, rowIndex });
      }
      return { val, row };
    },
    // 删除单行
    deleteClick({ val, row, rowIndex }) {
      this.list.splice(rowIndex, 1);
      this.$emit('onGetfValue', this.list);
    },
    // 打开抽屉
    openModal({ val, row, rowIndex }) {
      this.modalName = val;
      this.modalConfig = {
        visible: true,
        type: 'Drawer',
        title: '上传物料核销资料',
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { title: '', type: '', visible: false };
    },
    // 确定弹窗
    onModalOk() {
      if (this.modalName === 'add_materiel') {
        this.list = this.showList;
      }
      this.$emit('onGettValue', this.list);
      this.modalConfig = { title: '', type: '', visible: false };
      this.modalName = '';
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  box-sizing: border-box;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.buttons {
  margin-bottom: 12px;
}
</style>
