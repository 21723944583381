<template>
  <div class="table">
    <div class="buttons" v-if="!disabled">
      <el-button type="primary" @click="addTableList">新增</el-button>
      <el-button type="danger" @click="clearSelectConfig">清空</el-button>
    </div>
    <vxe-table
      border
      show-overflow
      ref="fTable"
      :data="list"
      :edit-config="{
         trigger: 'click',
        mode: 'cell',
        showIcon: false,
        immediate: true,
        activeMethod: activeCellMethod,
      }"
    >
      <vxe-table-column width="120" title="活动明细名称" field="actDetailName"></vxe-table-column>
      <vxe-table-column width="120" title="活动明细编码" field="actDetailCode"></vxe-table-column>
      <vxe-table-column width="120" title="附加信息行编号" field="attachCode"></vxe-table-column>
      <vxe-table-column width="120" title="投入类型名称" field="categoriesName"></vxe-table-column>
      <vxe-table-column width="120" title="投入类型编码" field="categoriesCode"></vxe-table-column>
      <vxe-table-column width="120" title="所属销售公司" field="ownedSalesCompanyName"></vxe-table-column>
      <vxe-table-column width="120" title="所属客户" field="ownedCusCodesName"></vxe-table-column>
      <vxe-table-column width="120" title="支付类型" field="payTypeName"></vxe-table-column>
      <vxe-table-column width="120" title="归属客户名称" field="attributionCusName"></vxe-table-column>
      <vxe-table-column width="120" title="归属月份" field="attributionMonth"></vxe-table-column>
      <vxe-table-column width="120" title="归属季度" field="attributionQuarter"></vxe-table-column>
      <vxe-table-column width="120" title="产品层级" field="productLevelName"></vxe-table-column>
      <vxe-table-column width="120" title="产品" field="productName"></vxe-table-column>
      <vxe-table-column width="120" title="单位" field="unitName"></vxe-table-column>
      <vxe-table-column width="120" title="单价" field="price"></vxe-table-column>
      <vxe-table-column width="120" title="申请数量" field="applyNumber"></vxe-table-column>
      <vxe-table-column width="120" title="申请金额" field="applyAmount"></vxe-table-column>
      <vxe-table-column
        width="120"
        title="上账核销金额"
        field="auditAmount"
        :formatter="
          ({ cellValue }) => {
            if (!cellValue) {
              return 0;
            }
            return cellValue;
          }
        "
        :edit-render="editRender('input', 'auditAmount', '上账核销金额')"
      ></vxe-table-column>
      <vxe-table-column width="120" title="支付人" field="payMan"></vxe-table-column>
      <vxe-table-column width="120" title="开户行" field="accountBank"></vxe-table-column>
      <vxe-table-column width="120" title="账号" field="account"></vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'addition_table',
  components: {
    SelectConfig,
  },
  props: {
    value: Array,
    auditFormCode: String,
    disabled: Boolean,
    isUpdate: Boolean,
    detailsVos: Array,
  },
  data: () => ({
    list: [],
    modalName: '', // 打开模块类型
  }),
  watch: {
    value(val) {
      this.list = val;
    },
    detailsVos(val) {
      const list = val.map((v) => v.actDetailCode);
      this.filterSelect(list);
    },
    auditFormCode() {
      if (this.isUpdate) {
        this.clearSelectConfig();
      }
    },
  },
  methods: {
    // 新增弹窗
    addTableList() {
      if (this.detailsVos.length === 0) {
        this.$message({
          type: 'error',
          message: '请先选择核销信息',
        });
        return;
      }
      const params = {
        functionCode: 'tpm_audit_detail_attach_list',
        data: this.list || [],
        selectionList: [],
        idKey: 'actDetailCode',
        paramData: { actDetailCodeList: this.detailsVos.map((v) => v.actDetailCode) },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    activeCellMethod({ column, columnIndex }) {
      if (!this.disabled) {
        return true;
      }
      return false;
    },
    // 配置自定义组件
    editRender(type, title, field) {
      // const { type, title, field } = item;
      let tmp = {
        name: '',
      };
      if (type && !this.disabled) {
        tmp = {
          name: '$input',
          props: {
            placeholder: `请输入${title}`,
            transfer: true,
            type: 'number',
            min: '0',
          },
          events: {
            change: (row) => {
              this.onGetSelect(this.list);
            },
          },
        };
      }
      return tmp;
    },
    // 弹窗回调
    onGetSelect(val) {
      this.list = val;
      this.$emit('onGetsValue', val);
    },
    // 清空列表
    clearSelectConfig() {
      this.list = [];
      this.$emit('onGetsValue', this.list);
    },
    // 筛选列表
    filterSelect(val) {
      const list = [];
      val.map((item) => {
        this.list.map((v) => {
          if (v.actDetailCode === item) {
            list.push(v);
          }
          return v;
        });
        return item;
      });
      this.onGetSelect(list);
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  box-sizing: border-box;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.buttons {
  margin-bottom: 12px;
}
</style>
