<template>
  <div class="table">
    <el-tabs v-model="activeName">
      <el-tab-pane label="核销信息" name="first"></el-tab-pane>
      <el-tab-pane label="附加信息" name="second"></el-tab-pane>
      <el-tab-pane label="物料池信息" name="third"></el-tab-pane>
    </el-tabs>
    <CancelList
      v-show="activeName === 'first'"
      @onGetfValue="onGetfValue"
      :value="fVal"
      :actCode="actCode"
      :isUpdate="isUpdate"
      :auditFormCode="auditFormCode"
      :fineCode="fineCode"
      :columns="columns"
      :disabled="disabled"
      :isAllowRepeatAudit="isAllowRepeatAudit"
    />
    <AdditionList
      v-show="activeName === 'second'"
      @onGetsValue="onGetsValue"
      :value="sVal"
      :isUpdate="isUpdate"
      :disabled="disabled"
      :detailsVos='fVal'
    />
      <!-- :actDetailCodeList="actDetailCodeList" -->
    <MaterialList
      v-show="activeName === 'third'"
      @onGettValue="onGettValue"
      :value="tVal"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import CancelList, { AdditionList, MaterialList } from './edit_table_list';
import request from '../../../../../../../utils/request';

export default {
  name: 'additional',
  data() {
    return {
      activeName: 'first',
      columns: [],
      fVal: [],
      sVal: [],
      tVal: [],
      // actDetailCodeList: [],
    };
  },
  components: {
    CancelList,
    AdditionList,
    MaterialList,
  },
  props: {
    value: Object,
    isUpdate: Boolean,
    disabled: Boolean,
    fineCode: String,
    auditFormCode: String,
    actCode: String,
    isAllowRepeatAudit: String,
  },
  watch: {
    auditFormCode() {
      this.getNavByAuditFormCode();
    },
    value(val) {
      this.fVal = val.fVal;
      this.sVal = val.sVal;
      this.tVal = val.tVal;
    },
  },
  methods: {
    getNavByAuditFormCode() {
      request
        .post('/mdm/mdmtableconfig/query', {
          functionCode: this.auditFormCode,
          parentCode: 'CRM20210315000001411',
        })
        .then((res) => {
          if (res.success) {
            this.columns = res.result.column.filter((v) => v.visible);
          }
        });
    },
    onGetfValue(val /* actDetailCodeList */) {
      this.value.fVal = val;
      this.fVal = val;
      // this.actDetailCodeList = actDetailCodeList;
      this.$emit('change', this.value, 'fVal');
    },
    onGetsValue(val) {
      this.value.sVal = val;
      this.sVal = val;
      this.$emit('change', this.value, 'sVal');
    },
    onGettValue(val) {
      this.value.tVal = val;
      this.tVal = val;
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  box-sizing: border-box;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.buttons {
  margin-bottom: 12px;
}
</style>
