var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      !_vm.disabled
        ? _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addTableList } },
                [_vm._v("新增")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.clearSelectConfig },
                },
                [_vm._v("清空")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "fTable",
          attrs: {
            border: "",
            "show-overflow": "",
            data: _vm.list,
            "edit-config": {
              trigger: "click",
              mode: "cell",
              showIcon: false,
              immediate: true,
              activeMethod: _vm.activeCellMethod,
            },
          },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "活动明细名称",
              field: "actDetailName",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "活动明细编码",
              field: "actDetailCode",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "附加信息行编号",
              field: "attachCode",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "投入类型名称",
              field: "categoriesName",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "投入类型编码",
              field: "categoriesCode",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "所属销售公司",
              field: "ownedSalesCompanyName",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "所属客户",
              field: "ownedCusCodesName",
            },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "支付类型", field: "payTypeName" },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "归属客户名称",
              field: "attributionCusName",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "归属月份",
              field: "attributionMonth",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "归属季度",
              field: "attributionQuarter",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "产品层级",
              field: "productLevelName",
            },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "产品", field: "productName" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "单位", field: "unitName" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "单价", field: "price" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "申请数量", field: "applyNumber" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "申请金额", field: "applyAmount" },
          }),
          _c("vxe-table-column", {
            attrs: {
              width: "120",
              title: "上账核销金额",
              field: "auditAmount",
              formatter: function (ref) {
                var cellValue = ref.cellValue

                if (!cellValue) {
                  return 0
                }
                return cellValue
              },
              "edit-render": _vm.editRender(
                "input",
                "auditAmount",
                "上账核销金额"
              ),
            },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "支付人", field: "payMan" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "开户行", field: "accountBank" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "120", title: "账号", field: "account" },
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }