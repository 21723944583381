var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      !_vm.disabled
        ? _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addTableList } },
                [_vm._v("新增")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.clearSelectConfig },
                },
                [_vm._v("清空")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: {
            resizable: "",
            "show-overflow": "",
            data: _vm.list,
            "radio-config": { highlight: true },
            "edit-config": {
              trigger: "click",
              mode: "cell",
              immediate: true,
              activeMethod: _vm.activeCellMethod,
            },
          },
        },
        [
          _vm._l(_vm.columns, function (item) {
            return _c("vxe-table-column", {
              key: item.field,
              attrs: {
                field: item.field,
                title: item.title,
                formatter: _vm.formatter,
                "min-width": 135,
                "edit-render": _vm.editRender(item),
              },
              scopedSlots: _vm._u(
                [
                  item.render
                    ? {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(_vm._s(item.render && item.render(row))),
                            ]),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }),
          this.columns.length > 0
            ? _c("vxe-table-column", {
                attrs: {
                  title: "操作",
                  width: "60",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "el-dropdown",
                            {
                              on: {
                                command: function (val) {
                                  return _vm.clickCell({
                                    val: val,
                                    row: row,
                                    rowIndex: rowIndex,
                                  })
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v("更多"),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "upload" } },
                                    [_vm._v("核销资料")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "activity" } },
                                    [_vm._v("活动取证资料")]
                                  ),
                                  !_vm.disabled
                                    ? _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "delete" } },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3130472044
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c(
        "Modal",
        {
          ref: "modalForm",
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [
          _c(
            "vxe-table",
            { ref: "fTable", attrs: { border: "", data: _vm.cancelValue } },
            [
              _c("vxe-table-column", {
                attrs: { title: "要求核销资料", field: "exampleName" },
              }),
              _c("vxe-table-column", {
                attrs: { title: "描述", field: "remarks" },
              }),
              _c("vxe-table-column", {
                attrs: { title: "示例", field: "pictureRespVos" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticClass: "img-list" },
                          [
                            _c("el-image", {
                              staticStyle: { "max-width": "80px" },
                              attrs: {
                                src: _vm.getImgUrls(row.pictureRespVos)[0],
                                "preview-src-list": _vm.getImgUrls(
                                  row.pictureRespVos
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.modalName === "upload"
                ? _c("vxe-table-column", {
                    attrs: {
                      title: "上传",
                      width: "130",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              !_vm.disabled
                                ? _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        action:
                                          "/upload/uploadController/uploadOssList",
                                        headers: {
                                          loginUserToken:
                                            _vm.Storage.l.get("TokenKey"),
                                        },
                                        "on-remove": _vm.handleRemove,
                                        "on-success": function (res) {
                                          return _vm.handleSuccess(res, row)
                                        },
                                        multiple: "",
                                        accept:
                                          ".jpg, .jpeg, .png, .JPG, .JPEG, .PNG",
                                        "file-list":
                                          _vm.fileList[row.exampleCode] || [],
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [_vm._v("点击上传")]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "file-list-detail" },
                                    _vm._l(
                                      _vm.fileList[row.exampleCode] || [],
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "file-list",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-tickets",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "file-name" },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    item.url +
                                                    "?response-content-type=application/octet-stream",
                                                  download: item.name,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-download",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      630644209
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm._t("footer", function () {
                return [
                  _vm.modalName !== "add_materiel"
                    ? _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-close", type: "danger" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("关闭")]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }