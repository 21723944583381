import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import CustomTable from '../components';
import inputClick from '../components/input_click/index';

formCreate.component('ActivityWriteOffTable', CustomTable);
formCreate.component('inputClick', inputClick);

export default {
  extends: Form,
  props: {
    id: String,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20210315000001411',
      formFunctionCode: 'tpm_activity_write_off_form',
      buttonText: {
        submit: '保存',
        close: '关闭',
        submitAudit: '保存并提交',
      },
      buttons: {
        submit: true,
        close: true,
        loading: false,
        submitAudit: true,
      },
      auditFormCode: '',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'table') {
        v.value = {
          fVal: [],
          sVal: [],
          tVal: [],
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const actName = this.getRule('actName');
      const table = this.getRule('table');
      actName.on.change = (e) => {
        this.setValue({
          actName: e.actName,
          actCode: e.actCode,
          isAllowRepeatAudit: e.isAllowRepeatAudit,
        });
        this.auditFormCode = e.auditFormCode;
        console.log(e.isAllowRepeatAudit);
        this.updateRules({
          table: {
            props: {
              auditFormCode: e.auditFormCode,
              actCode: e.actCode,
              fineCode: e.fineCode,
              isUpdate: true,
              isAllowRepeatAudit: e.isAllowRepeatAudit,
            },
          },
        });
      };

      table.on.change = (e, type) => {
        let totalAmount = 0;
        if (type === 'fVal') {
          this.updateRules({
            table: {
              props: {
                isUpdate: true,
              },
            },
          });

          e.fVal.map((item) => {
            const v = item;
            v.amount = Number(v.billAuditAmount) + Number(v.materialsAmount);
            totalAmount += v.amount || 0;
            return v;
          });
          this.setValue({
            totalAmount,
            materialsAmount: 0, // 没有物料池，目前是假数据
          });
        } else {
          e.fVal.map((v) => {
            const it = v;
            let billAuditAmount = 0;
            e.sVal.map((item) => {
              if (v.actDetailCode === item.actDetailCode) {
                billAuditAmount += item.auditAmount - 0;
              }
              return item;
            });
            it.billAuditAmount = billAuditAmount;
            it.amount = Number(billAuditAmount) + Number(it.materialsAmount);
            totalAmount += it.amount || 0;
            return it;
          });

          this.setValue({
            totalAmount,
          });
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view' || this.id) {
        request
          .get('/tpm/tpmliqueuraudit/query', {
            id: this.formConfig.row ? this.formConfig.row.id : this.id,
          })
          .then((res) => {
            if (res.success) {
              const tableList = {
                fVal: res.result.detailsVos,
                sVal: res.result.attachVos,
                tVal: res.result.auditFileReqVos,
              };

              this.auditFormCode = res.result.auditFormCode;

              this.setValue(res.result);
              this.setValue({
                table: tableList,
              });

              this.updateRules({
                table: {
                  props: {
                    auditFormCode: res.result.auditFormCode,
                    actCode: res.result.actCode,
                    fineCode: res.result.fineCode,
                    isUpdate: false,
                  },
                },
              });
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmliqueuraudit/save';
        const params = {
          ...formData,
        };
        params.detailsVos = params.table.fVal;
        params.attachVos = params.table.sVal;
        params.auditFileReqVos = params.table.tVal;
        params.detailsVos.map((v) => {
          const item = v;
          if (!item.billAuditAmount) {
            item.billAuditAmount = 0;
          }
          if (!v.materialsAmount) {
            item.materialsAmount = 0;
          }
          if (!v.amount) {
            item.amount = 0;
          }
          if (!v.applyAmount) {
            item.applyAmount = 0;
          }
          return item;
        });
        params.attachVos.map((v) => {
          const item = v;
          if (this.formConfig.code === 'add') {
            item.id = '';
          }
          if (!item.auditAmount) {
            item.auditAmount = 0;
          }
          if (!v.applyAmount) {
            item.applyAmount = 0;
          }
          if (!v.applyNumber) {
            item.applyNumber = 0;
          }
          return item;
        });

        delete params.table;

        params.saveType = e.toString();

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmliqueuraudit/update';
          params.id = this.formConfig.row.id;
          params.saveType = (e + 2).toString();
        }

        params.auditFormCode = this.auditFormCode;
        console.log(params);
        if (e === 2) {
          this.$emit('submit', {
            row: params,
            submitUrl: url,
          });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '操作成功',
              });
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
